import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PageBuilder from "components/PageBuilder/PageBuilder"
import Wrap from "components/Wrap"
import Seo from "components/Seo"
import { Banner } from "components/Banner"
import Popup from "components/Popup"

export default function TemplatePagesClubs(props) {
  const { data, pageContext } = props
  const { entry } = data?.craft
  const { seoTitle, seoDescription, seoImage, seoCanonicalTag } = entry

  const curPageTitle = entry.title
  const offerPopupHeadline = entry.offerPopupHeadline
  const offerPopupExpirationDate = entry.offerPopupExpirationDate
  const offerPopupType = entry.offerPopupType
  const offerPopupLayout = entry.offerPopupLayout
  const offerPopupStretch = entry.offerPopupStretch
  const offerPopupPosition = entry.offerPopupPosition
  const fullUri =
    entry.offerPopupLink.entry && entry.offerPopupLink.entry.fullUri

  useEffect(() => {
    setTimeout(() => {
      if (
        entry.trackFacebookEvent &&
        typeof window !== "undefined" &&
        typeof window.fbq !== "undefined"
      ) {
        window.fbq("track", entry.trackFacebookEvent)
      }
      /*
        Passing an empty array as second argument
        triggers the callback in useEffect only after the initial render
        thus replicating `componentDidMount` lifecycle behaviour
      */
    }, 500)
  }, [])

  return (
    <>
      <Seo
        title={seoTitle || entry.title}
        description={seoDescription}
        canonical={seoCanonicalTag}
        image={seoImage}
        defaults={data?.craft?.globals?.seo}
        pageContext={pageContext}
      />
      <Wrap minHeight='100vh'>
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          pageContext={pageContext}
        />
        <Banner
          fullUri={fullUri}
          offerPopupHeadline={offerPopupHeadline}
          offerPopupType={offerPopupType}
          offerPopupLayout={offerPopupLayout}
          offerPopupStretch={offerPopupStretch}
          offerPopupPosition={offerPopupPosition}
          curPageTitle={curPageTitle}
          offerPopupExpirationDate={offerPopupExpirationDate}
          pageContext={pageContext}
        />
        <Popup pageContext={pageContext} />
      </Wrap>
    </>
  )
}

export const query = graphql`
  query TemplatePagesClubs($id: [Int]!, $siteId: Int!) {
    craft {
      # Globals
      globals(siteId: $siteId) {
        ...GlobalNavigationSocialQuery
        ...GlobalCtaSignUpQuery
        ...GlobalFooterSectionQuery
        ...GlobalCookieInfoQuery
        ...GlobalSeoQuery
        ...GlobalBannerQuery
      }

      # NavigationHeader
      navigationHeaderItems: entries(
        section: navigationHeader
        siteId: $siteId
      ) {
        ...NavigationHeaderQuery
      }

      # NavigationFooter
      navigationFooterItems: entries(
        section: navigationFooter
        siteId: $siteId
      ) {
        ...NavigationFooterQuery
      }

      # Page
      entry(id: $id, siteId: $siteId) {
        ... on Craft_PagesPagesClubs {
          title
          seoTitle
          seoDescription
          seoCanonicalTag
          trackFacebookEvent
          seoImage {
            optimizedUrl: url(crop: { width: 1080, quality: 90 })
          }
          pageBuilder {
            ...PageBuilderQuery
          }
          offerPopupHeadline {
            content
          }
          offerPopupExpirationDate
          offerPopupType
          offerPopupLayout
          offerPopupStretch
          offerPopupPosition
          offerPopupLink {
            entry {
              fullUri
            }
          }
        }
      }
    }
  }
`
